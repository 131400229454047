import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Amplify } from "aws-amplify";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Landing from "views/pages/Landing";

import NameAvailability from "views/pages/flows/newBusiness/nameAvailability";
import StructureSelection from "views/pages/flows/newBusiness/structureSelection";
import ABNApplication from "views/pages/flows/newBusiness/abnApplication";
import ABNReview from "views/pages/flows/newBusiness/abnReview";

import BNRegistrationDetails from "views/pages/flows/registerBusinessName/businessNameDetails";
import BNOwner from "views/pages/flows/registerBusinessName/businessNameOwner";

import CompanyName from "views/pages/flows/registerCompany/companyName";
import RegisteredOffice from "views/pages/flows/registerCompany/registeredOffice";
import CompanyMembers from "views/pages/flows/registerCompany/companyMembers";
import ShareStructure from "views/pages/flows/registerCompany/shareStructure";

import SelectPlan from "views/pages/flows/selectPlan";
import Checkout from "views/pages/flows/checkout";
import RegisterPasskey from "views/pages/flows/registerPasskey";

import ThankYou from "views/pages/flows/thankYou";

import Login from "views/pages/Login";
import Register from "views/pages/Register";
import Verify from "views/pages/Verify";
import ResetPassword from "views/pages/ResetPassword";
import ResetPasswordVerify from "views/pages/ResetPasswordVerify";

import AuthorityList from "./views/pages/AuthorityList";

import RegisterBiometrics from "views/pages/RegisterBiometrics";
import LoginBiometrics from "views/pages/LoginBiometrics";

import Terms from "views/pages/Terms";
import PrivacyPolicy from "views/pages/PrivacyPolicy";
import AccountDeletion from "views/pages/AccountDeletion";

import MerchantLayout from "layouts/MerchantLayout";
import CustomerLayout from "layouts/CustomerLayout";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
      userPoolClientId:
        process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    },
  },
});

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://e4bab719322443ea8337e884b323be4a@o4504819869614080.ingest.sentry.io/4504819870728192",
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/plans/sole-trader"
        exact
        component={() => {
          window.location.href =
            process.env.REACT_APP_STRIPE_PAYMENT_LINK_MICROBUSINESS;
          return null;
        }}
      />
      <Route
        path="/plans/sole-trader-gst"
        exact
        component={() => {
          window.location.href =
            process.env.REACT_APP_STRIPE_PAYMENT_LINK_SCALEUP;
          return null;
        }}
      />
      <Route
        path="/plans/company-accounting"
        exact
        component={() => {
          window.location.href =
            process.env.REACT_APP_STRIPE_PAYMENT_LINK_LIFTOFF;
          return null;
        }}
      />
      <Route
        path="/"
        exact
        component={() => {
          window.location.href = "/sign-in";
          return null;
        }}
      />
      <Route path="/" exact render={(props) => <Landing {...props} />} />
      <Route
        path="/sign-in-legacy"
        exact
        render={(props) => <Login {...props} />}
      />
      <Route
        path="/sign-in"
        exact
        render={(props) => <LoginBiometrics {...props} />}
      />
      <Route
        path="/register-legacy"
        exact
        render={(props) => <Register {...props} />}
      />
      <Route
        path="/register"
        exact
        render={(props) => <RegisterBiometrics {...props} />}
      />
      <Route path="/verify" exact render={(props) => <Verify {...props} />} />
      <Route
        path="/reset-password"
        exact
        render={(props) => <ResetPassword {...props} />}
      />
      <Route
        path="/reset-password-verify"
        exact
        render={(props) => <ResetPasswordVerify {...props} />}
      />
      <Route
        path="/authorities"
        exact
        render={(props) => <AuthorityList {...props} />}
      />
      <Route
        path="/name-availability"
        exact
        render={(props) => <NameAvailability {...props} />}
      />
      <Route
        path="/structure-selection"
        exact
        render={(props) => <StructureSelection {...props} />}
      />
      <Route
        path="/lodge/business-name-application"
        exact
        render={(props) => <BNRegistrationDetails {...props} />}
      />
      <Route
        path="/lodge/business-name-application/owner-details"
        exact
        render={(props) => <BNOwner {...props} />}
      />
      <Route
        path="/lodge/abn-application"
        exact
        render={(props) => <ABNApplication {...props} />}
      />
      <Route
        path="/lodge/abn-application/review"
        exact
        render={(props) => <ABNReview {...props} />}
      />
      <Route
        path="/lodge/company-registration"
        exact
        render={(props) => <CompanyName {...props} />}
      />
      <Route
        path="/lodge/company-registration/registered-office"
        exact
        render={(props) => <RegisteredOffice {...props} />}
      />
      <Route
        path="/lodge/company-registration/members"
        exact
        render={(props) => <CompanyMembers {...props} />}
      />
      <Route
        path="/lodge/company-registration/share-structure"
        exact
        render={(props) => <ShareStructure {...props} />}
      />
      <Route
        path="/select-plan"
        exact
        render={(props) => <SelectPlan {...props} />}
      />
      <Route
        path="/checkout"
        exact
        render={(props) => <Checkout {...props} />}
      />
      <Route
        path="/register-passkey"
        exact
        render={(props) => <RegisterPasskey {...props} />}
      />
      <Route
        path="/thank-you"
        exact
        render={(props) => <ThankYou {...props} />}
      />
      <Route path="/terms" exact render={(props) => <Terms {...props} />} />
      <Route
        path="/privacy-policy"
        exact
        render={(props) => <PrivacyPolicy {...props} />}
      />
      <Route
        path="/account-deletion"
        exact
        render={(props) => <AccountDeletion {...props} />}
      />
      <Route
        path="/customer"
        render={(props) => <CustomerLayout {...props} />}
      />
      <Route
        path="/dashboard"
        render={(props) => <MerchantLayout {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
